<template>
  <AppBar>
    <ListData
      :tableData="tableData"
      isBtn="23"
      @RefreshData="RefreshData"
    ></ListData>
    <el-pagination
      style="margin-top: 20px;"
      :hide-on-single-page="true"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-size="20"
      background
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>
  </AppBar>
</template>
<style lang="less" scoped></style>
<script>
import AppBar from "../../components/AppBar";
import ListData from "../../components/ListData";
export default {
  data() {
    return {
      tableData: [],
      currentPage: 1,
      total: 0
    };
  },
  methods: {
    handleCurrentChange(page) {
      this.tableData = [];
      this.$common.getInterface(
        "/api/v1.projects/getMyPost",
        { state: 1 ,page:page},
        this,
        res => {
          // console.log(res);
          this.tableData = res.data.data;
        }
      );
    },
    RefreshData() {
      this.$common.getInterface(
        "/api/v1.projects/getMyPost",
        { state: 1 },
        this,
        res => {
          // console.log(res);
          this.tableData = res.data.data;
        }
      );
    }
  },
  created() {
    this.$common.getInterface(
      "/api/v1.projects/getMyPost",
      { state: 1 },
      this,
      res => {
        // console.log(res);
        this.tableData = res.data.data;
        this.total = this.tableData.length;
      }
    );
  },
  components: {
    AppBar,
    ListData
  }
};
</script>
